import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Wave from '../components/wave';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Contact Me`}</h1>
    <p>{`You can get in touch with me by smoke signal`}</p>
    <h2>{`Or you can wave to me.`}</h2>
    <p>{`Just click this button.`}</p>
    <Wave mdxType="Wave" />
    <p>{`I might not wave back.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      